<template>
    <div>
        <div class="header">
            <h1 class="heading">{{title}}</h1>
            <v-btn class="btn_plus" 
                color="primary" 
                outlined
                   dense
                   small
                @click="goToCreateObject"
            >
                {{$t('objects["Новый объект"]')}}
            </v-btn>
        </div>

        <h2 class="objects-empty">{{$t('objects["Здесь пока пусто, добавьте свой объект."]')}}</h2>
    </div>
</template>

<script>
export default {
    props: {
        title: String
    },
    data: () => ({

    }),
    methods: {
        async goToCreateObject() {
            await this.$router.push({name: 'ObjectsCreate', params: {lang: this.$route.params.lang}})
        },
    }
};
</script>

<style lang="scss">
.objects-empty {
    position: relative;
    display: flex;
    max-width: 350px;
    margin: 280px auto;
    padding-top: 150px;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.33;
    color: #a3a4a5;
    background: url(~@/assets/img/hotel.svg) center top/120px no-repeat;
    &::after {
        content: '';
        position: absolute;
        top: -110%;
        left: 120%;
        width: 198px;
        height: 364px;
        background: url(~@/assets/img/arrow.svg) center/contain no-repeat;
    }
}

.left {
    display: flex;
    flex-wrap: wrap;
}

.tovar {
    width: 25%;
}
</style>