<template>
    <main >
        <template v-if="objects.list.length">
            <div class="header">
                <h1 class="heading">{{$t('stickers["Стикеры"]')}}</h1>
                <v-btn class="btn_plus"
                       style="margin-right: 15px;"
                    color="primary"
                       outlined
                       dense small
                    :to="{name: 'StickersCreate', params: { lang: $route.params.lang}}"
                >
                    {{$t('stickers["Новый заказ стикеров"]')}}
                </v-btn>
                <v-btn class="btn_plus"
                       color="primary"
                       outlined
                       dense small
                       :to="{name: 'StickerConstructor', params: { lang: $route.params.lang}}"
                >
                    Create custom sticker
                </v-btn>
            </div>

            <!-- {{stickers}} -->
            <!-- {{objects}} -->
            
<!--            <v-data-table class="table"-->
<!--                v-if="stickers.list.length"-->
<!--                :headers="headers"-->
<!--                :items="stickers.list"-->
<!--                :items-per-page="999"-->
<!--                hide-default-footer-->
<!--            >-->
<!--                <template v-slot:body="{ items }">-->
<!--                    <tbody v-if="items.length">-->
<!--                        <tr v-for="item in items" :key="item.id">-->
<!--                            <td class="d-block d-sm-table-cell">{{ item.id }}</td>-->
                            <!-- <td class="d-block d-sm-table-cell">{{ item.object }}</td> -->
<!--                            <td class="d-block d-sm-table-cell">{{ (objects.list.find(object => object.id === item.hotel_id)).name }}</td>-->
<!--                            <td class="d-block d-sm-table-cell">{{ item.number_stickers }} {{$t('stickers["шт."]')}}</td>-->
<!--                            <td class="d-block d-sm-table-cell">{{ item.type_stickers.name }}</td>-->
                            <!-- <td class="d-block d-sm-table-cell">
                                <template v-if="item.type === 1">Универсальный стикер</template>
                                <template v-if="item.type === 2">С вашим логотипом</template>
                                <template v-if="item.type === 3">Уникальный дизайн</template>
                            </td> -->
<!--                            <td class="d-block d-sm-table-cell">-->
<!--                                <span class="error&#45;&#45;text" v-if="item.stickers_order_status_id.id === 1">-->
<!--                                    <div class="d-flex align-center">-->
<!--                                        <span class="d-flex align-center" style="margin-right: 10px">-->
<!--                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><g><path fill="none" stroke="#ff5416" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" stroke-width="1.5" d="M5.102 1v0h5.796v0L15 5.102v5.796L10.898 15v0H5.102v0L1 10.898v0-5.796 0z"/></g><g><path fill="none" stroke="#ff5416" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" stroke-width="1.5" d="M10.1 5.9l-4.2 4.2"/></g><g><path fill="none" stroke="#ff5416" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" stroke-width="1.5" d="M5.9 5.9l4.2 4.2"/></g></g></g></svg>-->
<!--                                        </span>-->
<!--                                        {{$t('stickers["Отменен"]')}}-->
<!--                                    </div>-->
<!--                                </span>-->
<!--                                <div style="color: #7f8385" v-if="item.stickers_order_status_id.id === 2">-->
<!--                                    <div class="d-flex align-center">-->
<!--                                        <span class="d-flex align-center" style="margin-right: 10px">-->
<!--                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="15.5" viewBox="0 0 15.5 15.5"><g transform="translate(-1.25 -1.25)"><circle cx="7" cy="7" r="7" transform="translate(2 2)" fill="none" stroke="#7f8385" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M12,6v4.2l2.8,1.4" transform="translate(-3 -1.2)" fill="none" stroke="#7f8385" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g></svg>-->
<!--                                        </span>-->
<!--                                        {{$t('stickers["В работе"]')}}-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <span style="color: #7f8385" v-if="item.stickers_order_status_id.id === 3">-->
<!--                                    <div class="d-flex align-center">-->
<!--                                        <span class="d-flex align-center" style="margin-right: 10px">-->
<!--                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16"><g><g><g><path fill="none" stroke="#7f8385" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" stroke-width="1.5" d="M1 11.111v0V1v0h11.667v10.111z"/></g><g><path fill="none" stroke="#7f8385" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" stroke-width="1.5" d="M12.667 4.889v0h3.11v0l2.334 2.333v3.89h-5.444v0z"/></g><g><path fill="none" stroke="#7f8385" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" stroke-width="1.5" d="M2.556 13.056a1.944 1.944 0 1 1 3.888 0 1.944 1.944 0 0 1-3.888 0z"/></g><g><path fill="none" stroke="#7f8385" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" stroke-width="1.5" d="M12.667 13.056a1.944 1.944 0 1 1 3.889 0 1.944 1.944 0 0 1-3.89 0z"/></g></g></g></svg>-->
<!--                                        </span>-->
<!--                                        {{$t('stickers["Доставка"]')}}-->
<!--                                    </div>-->
<!--                                </span>-->
<!--                                <div class="success&#45;&#45;text" v-if="item.stickers_order_status_id.id === 4">-->
<!--                                    <div class="d-flex align-center">-->
<!--                                        <span class="d-flex align-center" style="margin-right: 10px">-->
<!--                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><g><path fill="none" stroke="#3fa535" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" stroke-width="1.5" d="M15 7.36v.644a7 7 0 1 1-4.151-6.398"/></g><g><path fill="none" stroke="#3fa535" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" stroke-width="1.5" d="M15 2.404v0L8 9.411v0l-2.1-2.1v0"/></g></g></g></svg>-->
<!--                                        </span>-->
<!--                                        {{$t('stickers["Выполнен"]')}}-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div style="color: #7f8385" v-if="item.stickers_order_status_id.id === 5">-->
<!--                                    <div class="d-flex align-center">-->
<!--                                        <span class="d-flex align-center" style="margin-right: 10px">-->
<!--                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="15.5" viewBox="0 0 15.5 15.5"><g transform="translate(-1.25 -1.25)"><circle cx="7" cy="7" r="7" transform="translate(2 2)" fill="none" stroke="#7f8385" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path d="M12,6v4.2l2.8,1.4" transform="translate(-3 -1.2)" fill="none" stroke="#7f8385" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g></svg>-->
<!--                                        </span>-->
<!--                                        {{$t('stickers["В работе"]')}}-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                    </tbody>-->
<!--                    <tbody v-else>-->
<!--                        <tr class="v-data-table__empty-wrapper">-->
<!--                            <td class="d-block d-sm-table-cell" colspan="5">{{$t('stickers["у Вас ещё нету стикеров, самое время их заказать :)"]')}}</td>-->
<!--                        </tr>-->
<!--                    </tbody>-->
<!--                </template>-->
                <!-- <template v-slot:no-data>
                    <v-alert :value="true" color="error" icon="warning">
                        Sorry, nothing to display here :(
                    </v-alert>
                </template> -->
<!--            </v-data-table>-->
            <div v-if="!stickers.list.length" class="no__stickers">In order to order stickers or stickers with qar codes and nfs tags, <router-link style="margin-left: 5px;"
                    :to="{name: 'StickersCreate', params: { lang: $route.params.lang}}"> click on the link</router-link></div>
        </template>
        <ObjectsEmpty :title="$t('stickers[\'Стикеры\']')" v-else />
    </main>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex';
import titleMixin from '@/mixins/titleMixin'

import ObjectsEmpty from '@/components/ObjectsEmpty.vue'

export default {
    name: 'Stickers',
    components: { 
        ObjectsEmpty,
    },
    title() { 
        return `${this.title}` 
    },
    mixins: [titleMixin],
    beforeRouteEnter (to, from, next) {
        if(!store.state.objects.list.length) {

            store.dispatch('objects/getList')
                .then(() => {
                    store.dispatch('stickers/getList')
                        .then(() => {
                            next();
                        }).catch(() => {
                        next();
                    })
                }).catch(() => {
                next();
            })
        }else{
            next();
        }
    },
    // created() {
    //     this.$store.dispatch('stickers/getList').then(() => {
    //         // console.log(stickers)
    //     });
    // },
    computed: {
        ...mapState(['objects'])
    },
    data() {
        return {
            stickers:{list:[]},
            title: this.$t('stickers["Стикеры"]'),
            headers: [
                { text: this.$t('stickers["Заказ"]'), value: 'id' },
                { text: this.$t('stickers["Объект"]'), value: 'object' },
                { text: this.$t('stickers["Количество стикеров"]'), value: 'number_stickers' },
                { text: this.$t('stickers["Тип"]'), value: 'type' },
                { text: this.$t('stickers["Статус заказа"]'), value: 'status' },
            ],
            // stickers: [
            //     {id: 355766, object: 'Skiles LLC', qtt: 944, type: 1, status: 3},
            //     {id: 200748, object: 'Murray Inc', qtt: 433, type: 2, status: 1},
            //     {id: 651091, object: 'Witting, Huel and Kemmer', qtt: 704, type: 3, status: 1},
            //     {id: 966103, object: 'Rosenbaum Inc', qtt: 618, type: 1, status: 1},
            //     {id: 361430, object: 'Metz, Bode and Hintz', qtt: 312, type: 1, status: 1},
            //     {id: 243625, object: 'Vandervort, Johns and Heidenreich', qtt: 281, type: 1, status: 1},
            //     {id: 718273, object: 'Waters LLC', qtt: 32, type: 2, status: 1},
            //     {id: 195555, object: 'Hills, Leuschke and Morissette', qtt: 670, type: 3, status: 2},
            //     {id: 763818, object: 'Smitham Group', qtt: 564, type: 3, status: 1},
            //     {id: 791650, object: 'Satterfield - Schaden', qtt: 317, type: 1, status: 1},
            // ]
        }
    },
    methods: {

    }
};
</script>

<style lang="scss">
.no__stickers{
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  padding-top:80px;
}
.v-data-table__empty-wrapper {

}
</style>